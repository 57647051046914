@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
.mynav{
  /* width: 100vw; */
  /* height: 50px; */
  
/* min-height:100px; */
  padding:5px;
  font-weight: bold;
  /* width:100vw; */
  line-height: 40px;
  background-color: white;
   /* background: linear-gradient(to bottom right, #26D0CE, #1A2980); */
 justify-content: space-between;
  padding-left:10px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.4);

 margin:0;
}



@keyframes slideGradient {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
 .logoDiv{
  margin-top:5px;
   display: flex;
   align-items: center;
   flex-direction: row;
 
 }
 .menu-hidden {
  transform: translateY(130%);
  transition: transform 0.2s ease-in-out, ;
}

/* .menu-visible {
  transform: translateY(0);
  transition: transform 0.2s ease-in-out;
}
.menu-hidden {
  transform: scale(0.0);
  transition: transform 0.2s ease-in-out, ;
} */

.menu-visible {
  transform: scale(1);
  transition: transform 0.2s ease-in-out;
}
.menu2-hidden {
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
}

.menu2-visible {
  opacity: 1;
  transition: opacity 0.1s ease-in-out;
}
 .bannerMain{
   width:100%;
   /* padding:10px; */
   display: flex;
   flex-direction: row;
   /* min-height: 70vh; */
   justify-content: center;
 }
 .categoryBanner{
   margin-top:5px;
   /* height:500px; */
   flex:1;

   display: flex;
   flex-direction: row;
   border:0.5px solid #e5e5e5;
   max-width: 1200px;
   background-color:#f8f8f8;
 }
 .catMenu{
   /* width:300px; */
   background-color: white;
   height:100%;
   border-right:0.5px solid #e5e5e5
 }
 .topcategory::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.topcategory {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.gradient-text {
  position: relative;
  font-size: 24px;
  font-weight: bold;
  background: linear-gradient(to right, var(--color1), var(--color2));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  background-size: 200% 100%;
  font-weight: bold;
  animation: changeColor 5s linear infinite; /* Adjust animation duration and timing as needed */
}

@keyframes changeColor {
  0% {
    background-position: 0% 50%;
  }
  25% {
    background-position: 25% 50%;
  }
  50% {
    background-position: 50% 50%;
  }
  75% {
    background-position: 75% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

/* Define your gradient colors as CSS variables */
:root {
  --color1: #FF512F;
  --color2: #DD2476;
}
@media (max-width: 600px) {
  .largeDevice {
    /* width: 0 !important;
    overflow: hidden; */
    display: none;
    /* Hide catMenu on small screens */
  }
}


.gradient-text::before {
  /* content: attr(data-text);  */
  position: absolute;
  left: 0;
  top: 0;
}

/* App.css */
.search-button {
  background: linear-gradient(to right, #FF8C00, #FF5733); /* Gradient colors */
  border: none;
  opacity: 1;
  /* z-index: 12; */
  /* height:40px; */
  border-radius: 0; /* No rounding by default */
  color: white;
  padding: 12px 20px; /* Adjust padding to fit the button content */
  position: relative;
  left: 0px; /* Overlap with the TextField border */
  cursor: pointer;
  line-height: 16px;
  user-select: none;
  /* transition: background 0.3s;  */
}

.search-button:hover {
  background: linear-gradient(to right, #FF5733, #FF8C00); /* Gradient colors on hover */
}

/* Add rounded right side */
.rounded-right {
  border-radius: 0 25px 25px 0; /* Rounded right side */
}

body {
  /* Style the scrollbar track */
  scrollbar-width: thin; /* Set the width of the scrollbar (thin) */
  scrollbar-color: #888 #f0f0f0; /* Set the color of the scrollbar thumb and track */
}

/* Style the scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background-color: #888; /* Set the color of the scrollbar thumb */
  border-radius: 4px; /* Make the thumb rounded */
}

/* Style the scrollbar track when the mouse is over it */
::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Change the color on hover */
}



 /* :root {
   --foreground-rgb: 0, 0, 0;
   --background-start-rgb: 214, 219, 220;
   --background-end-rgb: 255, 255, 255;
 }
 
 @media (prefers-color-scheme: dark) {
   :root {
     --foreground-rgb: 255, 255, 255;
     --background-start-rgb: 0, 0, 0;
     --background-end-rgb: 0, 0, 0;
   }
 }
 
 body {
   color: rgb(var(--foreground-rgb));
   background: linear-gradient(
       to bottom,
       transparent,
       rgb(var(--background-end-rgb))
     )
     rgb(var(--background-start-rgb));
 } */
 .menuitem:hover{
  color:orange
 }
 /* Basic styling for the div */
.hover-div {
overflow: hidden;
  cursor: pointer;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 5px 0 rgba(0,0,0,.23);
  transition: transform 0.4s ease-in-out;
}

/* Add a hover effect with a CSS animation */
.hover-div {
  animation: bounce 0.7s infinite;
}

/* Define the bounce animation */
@keyframes bounce {
  0%, 100% {
      transform: translateY(0);
  }
  50% {
      transform: translateY(-10px); /* Adjust the distance you want it to move */
  }
}

.hover-div2 {
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 5px 0 rgba(0,0,0,.23);
  transition: transform 0.4s ease-in-out;
}

/* Add a hover effect with a CSS animation */
.hover-div2 {
  animation: bounce2 0.7s infinite;
}

/* Define the bounce animation */
@keyframes bounce2 {
  0%, 100% {
      transform: translateY(0);
  }
  50% {
      transform: translateY(-10px); /* Adjust the distance you want it to move */
  }
}

.hover-div3 {
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 5px 0 rgba(0,0,0,.23);
  transition: transform 0.4s ease-in-out;
}

/* Add a hover effect with a CSS animation */
.hover-div3 {
  animation: bounce3 0.9s infinite;
}

/* Define the bounce animation */
@keyframes bounce3 {
  0%, 100% {
      transform: translateY(0);
  }
  50% {
      transform: translateY(-10px); /* Adjust the distance you want it to move */
  }
}


::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}